const Menu = [
  {
    heading: "menu",
    route: "/entity-organogram",
    pages: [
      {
        heading: "Manage Roles",
        route: "/entity-organogram/organogramview",
        fontIcon: "bi-dashboard",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Release Employee",
        route: "/entity-organogram/create-designation",
        fontIcon: "bi-dashboard",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Role Menu Permission",
        route: "/entity-organogram/menu-action-permission",
        fontIcon: "bi-dashboard",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      
    ],
  },
];

export default Menu;
